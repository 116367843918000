import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';

// Layout & SEO
import Layout from "../../components/layout"
import SEO from "../../components/seo"

// Components
import { BlogpostPreviewWithEnterance } from "../../components/blogpostPreview";

import './onclick-tutorials.scss'
import { themeContext } from "../../../stateProvider";



const TutorialsBlogPage = ({ data, location }) => {
  const { state = {} } = location
  const { recievedTag } = state
  const { state: themeState } = useContext(themeContext);
  const { t, i18n } = useTranslation();
  
  const getDataLocale = (data) => {
    let d = data.allMarkdownRemark.edges.filter((edge) => {
      return i18n.language.includes(edge.node.frontmatter.language);
    })

    return d
  }

  const getTags = data => {
    let uniqueTags = [];
    let tags = []

    data.forEach(edge => {
      tags = [...tags, ...edge.node.frontmatter.tags]
    })

    tags.forEach(tag => {
      if (!uniqueTags.includes(tag)) {
        uniqueTags.push(tag)
      }
    })
    return uniqueTags;
  }

  const [dataLocale, setDataLocale] = useState(data ? getDataLocale(data) : [])

  const [visiblePosts, setVisiblePosts] = useState(dataLocale ? dataLocale : []);
  const [tags, setTags] = useState(dataLocale ? getTags(dataLocale) : [])
  const [activeTag, setActiveTag] = useState(recievedTag ? recievedTag : 'all')

/*   const dataLocale = data.allMarkdownRemark.edges.filter((edge) => {
    return i18n.language.includes(edge.node.frontmatter.language);
  }); */


  const handleChangeActiveTag = (tag) => {
    setActiveTag(tag)
  }

  useEffect(() => {
    setDataLocale(getDataLocale(data))
  }, [i18n.language])

  useEffect(() => {
    let workingArray = [...dataLocale];

    if (activeTag === 'all') {
      setVisiblePosts([...workingArray])
    } else {
      let toCopyArray = dataLocale.filter(edge => {
        if (edge.node.frontmatter.tags.includes(activeTag)) {
          return edge
        }
      });

      setVisiblePosts([...toCopyArray]);
    }
  }, [activeTag, dataLocale]);
  
  return (
  <Layout>
    <SEO title="onClick! Tutorials" />
      <div className='tutorialsPage page'>
        <h1 
          className='tutorialsPage__heading'
        >
          {t("tutorialsPage.header.heading")}
        </h1>
        <div
          className='tutorialsPage__gist'
        >
          {t("tutorialsPage.header.gist")}
        </div>
        <div 
          className='tutorialsPage__tagsDiv'
        >
          <button
            className={`tutorialsPage__tagsDiv__tagBtn tutorialsPage__tagsDiv__tagBtn--${themeState.theme} ${activeTag === 'all' ? 'tutorialsPage__tagsDiv__tagBtn--active' : ''}`}
            onClick={() => handleChangeActiveTag('all')}
          >
            {t("tutorialsPage.header.tagBtnAll")}
          </button>
          {
          tags && tags.map(tag => (
          <button
            key={tag}
            className={`tutorialsPage__tagsDiv__tagBtn tutorialsPage__tagsDiv__tagBtn--${themeState.theme} ${activeTag === tag ? 'tutorialsPage__tagsDiv__tagBtn--active' : ''}`}
            onClick={() => handleChangeActiveTag(tag)}
          >
            {tag}
          </button>            
          ))
          }
        </div>
        {
          visiblePosts && visiblePosts.map(edge => (
            <BlogpostPreviewWithEnterance
              key={edge.node.id}
              blogpost={edge}
              locale={i18n.language}
            />
          ))
        }
      </div>
  </Layout>
  )
}

export default TutorialsBlogPage;

export const query = graphql`
query {
  allMarkdownRemark(filter: {frontmatter: {postType: {eq: "tutorial-post"}}}, 
    sort: { fields: [frontmatter___date], order: DESC }) {
    edges {
      node {
        excerpt
        id
        frontmatter {
          filename
          language
          frontPage
          frontPageImg
          description
          tags
          date
          title
          path
        }
      }
    }
  }
}
`